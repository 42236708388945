<template>
  <div>
    <HomeContainer>
      <template v-slot:header>
        <Header />
      </template>
      <template v-slot:main>
        <Homepage :title="pageTitle" />
      </template>
      <template v-slot:footer>
        <Footer />
      </template>
    </HomeContainer>
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer.vue";
// import Homepage from "@/components/Homepages/hpMcafee/Homepage.vue";
import Homepage from "@/components/Homepages/hpMcafee/NewDesign.vue";

import Header from "@/components/Header/Header.vue";
import HomeContainer from "@/components/Container/HomeContainer.vue";

export default {
  name: "HomeHpMcafee",
  components: {
    Header,
    Homepage,
    Footer,
    HomeContainer
  },
   data() {
    return {
      pageTitle: 'Bem vindo a página da HpMcafee',
    };
  },
};
</script>
